var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "nav",
      {
        staticClass:
          "flex flex-row px-3 mt-3 pb-2 border-b border-blue-500 items-end"
      },
      [
        _c(
          "router-link",
          { staticClass: "w-1/3", attrs: { to: { name: "login" } } },
          [
            _c("img", {
              staticClass: "h-7 w-7",
              attrs: { src: require("@/assets/images/back.svg") }
            })
          ]
        ),
        _c("h1", {
          staticClass: "w-1/3 text-center font-semibold",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_password_reset.label_password_reset")
            )
          }
        }),
        _c("div", { staticClass: "w-1/3 flex justify-end" }, [
          _c("button", {
            staticClass: "btn btn-blue rounded-full py-1 px-2",
            class: {
              "btn-loading": _vm.loading,
              "btn-blue cursor-pointer": !_vm.complete,
              "btn-blue-light cursor-not-allowed": _vm.complete
            },
            attrs: { disabled: _vm.loading || _vm.complete },
            domProps: {
              textContent: _vm._s(
                _vm.$t("the_password_reset.button_reset_password")
              )
            },
            on: { click: _vm.startReset }
          })
        ])
      ],
      1
    ),
    _c(
      "form",
      {
        staticClass: "flex-grow flex flex-col",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.startReset($event)
          }
        }
      },
      [
        _c("ul", { staticClass: "list-disc list-inside" }, [
          _vm.error
            ? _c("li", {
                staticClass: "form-error",
                domProps: { textContent: _vm._s(_vm.error) }
              })
            : _vm._e(),
          _vm.errors.has("email")
            ? _c("li", {
                staticClass: "form-error",
                domProps: { textContent: _vm._s(_vm.errors.first("email")) }
              })
            : _vm._e(),
          _vm.complete
            ? _c("li", {
                staticClass: "bg-green-500 py-2 pl-4 text-white uppercase",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_password_reset.label_reset_complete")
                  )
                }
              })
            : _vm._e()
        ]),
        _c("div", { staticClass: "flex-grow flex flex-col" }, [
          _c("div", { staticClass: "form-fieldset" }, [
            _c("label", {
              staticClass: "form-input-label",
              attrs: { for: "email" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("the_password_reset.placeholder_email")
                )
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "text",
                name: "email",
                "data-vv-validate-on": "blur",
                autofocus: "",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]),
          _c("p", {
            staticClass: "mx-4 mt-5 text-sm leading-tight",
            domProps: {
              textContent: _vm._s(_vm.$t("the_password_reset.label_reset_info"))
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }