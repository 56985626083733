<template>
  <div class="flex flex-col">
    <nav class="flex flex-row px-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <router-link
        :to="{name: 'login'}"
        class="w-1/3">
        <img
          src="@/assets/images/back.svg"
          class="h-7 w-7">
      </router-link>
      <h1
        v-text="$t('the_password_reset.label_password_reset')"
        class="w-1/3 text-center font-semibold"
      ></h1>
      <div class="w-1/3 flex justify-end">
        <button
          @click="startReset"
          class="btn btn-blue rounded-full py-1 px-2"
          :class="{'btn-loading': loading,
                   'btn-blue cursor-pointer': !complete,
                   'btn-blue-light cursor-not-allowed': complete}"
          :disabled="loading || complete"
          v-text="$t('the_password_reset.button_reset_password')"
        ></button>
      </div>
    </nav>
    <form
      @submit.prevent="startReset"
      class="flex-grow flex flex-col">
      <ul class="list-disc list-inside">
        <li
          v-if="error"
          v-text="error"
          class="form-error"></li>
        <li
          v-if="errors.has('email')"
          v-text="errors.first('email')"
          class="form-error"></li>
        <li
          v-if="complete"
          v-text="$t('the_password_reset.label_reset_complete')"
          class="bg-green-500 py-2 pl-4 text-white uppercase"></li>
      </ul>
      <div class="flex-grow flex flex-col">
        <div class="form-fieldset">
          <label
            for="email"
            v-text="$t('the_password_reset.placeholder_email')"
            class="form-input-label"></label>
          <input
            type="text"
            name="email"
            v-model="email"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            class="form-input"
            autofocus
            :placeholder="$t('general.placeholder_required')"
          >
        </div>
        <p
          v-text="$t('the_password_reset.label_reset_info')"
          class="mx-4 mt-5 text-sm leading-tight"
        ></p>
      </div>
    </form>
  </div>
</template>

<script>
  import validator from '@/mixins/validator';

  export default {
    name: 'ThePasswordReset',
    mixins: [validator],
    data() {
      return {
        email: null,
        error: null,
        loading: false,
        complete: false,
      };
    },
    methods: {
      startReset() {
        if (this.complete) {
          return;
        }

        this.$validator.validate().then(() => {
          this.error = null;

          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.loading = true;
          this.complete = false;

          this.$store.dispatch('auth/resetPassword', this.email)
            .then(() => {
              this.loading = false;
              this.complete = true;
            })
            .catch(this.handleError);
        });
      },
      handleError(error) {
        if (!error.response === undefined) {
          this.error = this.$t('general.label_error');
        }

        if (error.response.data.message) {
          this.error = error.response.data.message;
        }

        if (error.response.data.errors && error.response.data.errors.email) {
          [this.error] = error.response.data.errors.email;
        }
      },
    },
  };
</script>
